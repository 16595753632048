CoRobic.controller('AttractionsListController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", "$timeout", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window, $timeout
) {
    $scope.$emit('newPageLoaded', false);
    $scope.attractions = [];

    $scope.sorting = [
      {
        'name'  : 'Wybierz',
        'id'    : '',
        'ticked': false
      },
      {
        'name'  : 'Data dodania',
        'id'    : 'created',
        'ticked': true
      },
      {
        'name'  : 'Firmie',
        'id'    : 'company_name',
        'ticked': false
      },
      {
        'name'  : 'Nazwie atrakcji',
        'id'    : 'name',
        'ticked': false
      }
    ];



    $scope.firm = [];
    if(typeof $routeParams.business_name != 'undefined'){
      $scope.attr_search = $routeParams.business_name;
    }

    $scope.sort = 'created';
    $scope.sort_rev = true;

    $scope.$watch('sortt', function(newValue, oldValue){
      if(typeof newValue[0] != 'undefined'){
        if(newValue[0].name == 'Data dodania') {
          $scope.sort = 'created';
        }else if(newValue[0].name == 'Firmie') {
          $scope.sort = 'business_name';
        }else if(newValue[0].name == 'Nazwie atrakcji'){
          $scope.sort = 'name';
        }else{
          $scope.sort = 'created';
        }
      }
    });

    $scope.$watch('firmm', function(newValue, oldValue){
      if(typeof newValue[0] != 'undefined') {

        if(newValue[0].name == 'Wybierz') {
          $scope.firmm[0].name = ''; 
        }

      }
    });

    $scope.localLang = {
        selectNone      : "Odznacz",
        reset           : "ZAPISZ",
        search          : "Wpisz frazę...",
        nothingSelected : "Wybierz"         //default-label is deprecated and replaced with this.
    }
    $scope.firm.push({'name': 'Wybierz', 'id': '', 'ticked': false});
    userService.getUserBusinesses().then(function(response){
        console.log(response);
      $scope.businesses = response.data.response;
      angular.forEach( $scope.businesses , function( value, key ) {
        $scope.firm.push({'name': value.business_name, 'id': value.id, 'ticked': false});
        angular.forEach( value.attractions , function( va, k ) {
          va.business_name = value.business_name;
          $scope.attractions.push(va);
        });
      });
      $rootScope.loaders.homeCtrl.businesses = 0;
    });


    $rootScope.active_tab = 'atrakcje';
}]);