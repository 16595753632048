CoRobic.service('userService', ['$http', function($http) {

    this.verifiedOnly = function(city) {
        return $http({
            method: 'GET',
            url: base_path+'home/verifiedOnly/'+city
        });
     }

     this.saveUserData = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'users/saveUserData.json',
            data: data
        });
     }

     this.getUserBusinesses = function() {
         return $http({
            method: 'GET',
            url: base_path+'businesses/getUserBusinesses.json'
        });
     }

     this.fblogin = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'users/fblogin.json',
            data: data
        });
     }

     this.editBusiness = function(data) {
         return $http({
            method: 'POST',
            url: base_path+'businesses/edit/'+data.id+'.json',
            data: data
        });
     }

     this.saveBusiness = function(data) {
         return $http({
            method: 'POST',
            url: base_path+'businesses/add.json',
            data: data
        });
     }


    this.getAttraction = function(id) {
        return $http({
            method: 'GET',
            url: base_path+'attractions/get/'+id+'.json'
        });
    }

    this.getPopularCategories = function() {
       return $http({
            method: 'GET',
            url: base_path+'categories/popular.json'
        }); 
    }

    this.addImage = function(id, data) {
        return $http({
            method: 'POST',
            url: base_path+'attractions/addimage.json',
            data: {
                id: id,
                photo:data
            }
        });
    }

    this.removeImage = function(id) {
        return $http({
            method: 'POST',
            url: base_path+'attractions/removeimage.json',
            data: {
                id: id
            }
        });
    }

    this.editAttraction = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'attractions/edit/'+data.id+'.json',
            data: data
        });
    }

    this.saveAttraction = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'attractions/add.json',
            data: data
        });
    }

    this.getRecommended = function(model) {
        return $http({
            method: 'POST',
            url: base_path+'categories/recommended.json',
            data: {
              categories: model
            },
        });
    }

    this.saveVariant = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'attractionVariants/create.json',
            data: data
        });
    }
}]);