CoRobic.controller('BusinessAddController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", "$timeout", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window, $timeout
) {
    $scope.avatar = {};
    $rootScope.seo_title = 'Strefa biznesu - corobić.pl';
    $scope.businesseForm = {};
    $scope.business = null;
    $scope.step = 1;
    $rootScope.loaders.homeCtrl.addingBusiness = 0;
    $scope.step1_error = 0;
    $scope.step2_error = 0;
    $scope.step3_error = 0;
    $scope.step4_error = 0;
    $scope.step5_error = 0;
    $scope.postal_length  = 0;
    $scope.nip_length = 0;

    $rootScope.active_tab = 'biznesy';
  $scope.nextStep = function(){

      $rootScope.loaders.homeCtrl.steps = 1;
        if($scope.step < 4){
          if($scope.step == 3) {
            if($scope.business.street && $scope.business.street_nr && $scope.business.postal && $scope.business.postal.replace(/_/g,"").length == 6 && $scope.business.city && $scope.business.business_email && $scope.business.business_phone && $scope.business.nip && $scope.business.nip.replace(/_/g,"").length == 13){
              $scope.step = $scope.step + 1;
              $scope.step3_error = 0;

              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
            }else{
              if(typeof $scope.business.postal != 'undefined' && $scope.business.postal.replace(/_/g,"").length != 6)
              {
                $scope.postal_length = 1;
              }else{
                $scope.postal_length = 0;
              }
              if(typeof $scope.business.nip != 'undefined' && $scope.business.nip.replace(/_/g,"").length != 13)
              {
                $scope.nip_length = 1;
              }else{
                $scope.nip_length = 0;
              }
              $scope.step3_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

          if($scope.step == 1) {
            if($scope.business != null && typeof $scope.business.business_name != 'undefined' && $scope.business.business_name.length > 0 && typeof $scope.business.business_description != 'undefined' && $scope.business.business_description.length > 0){
              $scope.step = $scope.step + 1;
              $scope.step1_error = 0;
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
            }else{
              console.log('err');
              $scope.step1_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

          if($scope.step == 2) {
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
              $scope.step = $scope.step + 1;
              $scope.step2_error = 0;
              return;
          }

          if($scope.step == 4) {
            var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
              $scope.step = $scope.step + 1;
              $scope.step4_error = 0;

            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

        }


    }
    $scope.stepCheck = function(step){
      if(step <  $scope.step){
        $rootScope.loaders.homeCtrl.steps = 1;
          if(step <= 5 && step >= 1){
            $scope.step = step;
            if($scope.step == 2) {
              $scope.fClose();
            }
          }
        $timeout(function(){
          $rootScope.loaders.homeCtrl.steps = 0;
        },500);
      }
    }

    $scope.prevStep = function(){

      $rootScope.loaders.homeCtrl.steps = 1;
        if($scope.step > 1){
          $scope.step = $scope.step - 1;
          if($scope.step == 2) {
            $scope.fClose();
          }
        }
        $timeout(function(){
          $rootScope.loaders.homeCtrl.steps = 0;
        },500);

    }



    $scope.$emit('newPageLoaded', false);
    $scope.editorOptions = {
        language: 'pl',
        uiColor: '#000000'
    };



    $scope.addBusiness = function() {
      if($scope.avatar) {
        $scope.business.base64 = $scope.avatar.base64;
        $scope.business.base64type = $scope.avatar.filetype;
      }
  
          $rootScope.loaders.homeCtrl.addingBusiness = 1;
          userService.saveBusiness($scope.business).then(function(resp) {
            $rootScope.loaders.homeCtrl.addingBusiness = 0;
            window.location.href = base_path+'profil/biznesy';
          });


  }
}]);