CoRobic.controller('AttractionsEditController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", "$timeout", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window, $timeout
) {
$scope.avatar = false;
    $scope.avatar_check = false;
    $rootScope.seo_title = 'Atrakcje - corobić.pl';
    $scope.attractionForm = {};
    $scope.categories = {};
    $scope.categoryBrowserCheck = [];
    $scope.multiple_change = 0;
    $scope.editorOptions = {
        language: 'pl',
        uiColor: '#000000'
    };
    $rootScope.active_tab = 'atrakcje';



    
    $scope.localLang = {
      selectNone      : "Odznacz",
      reset           : "ZAPISZ",
      search          : "Wpisz frazę...",
      nothingSelected : "Wybierz"         //default-label is deprecated and replaced with this.
  }
    $rootScope.loaders.homeCtrl.addingBusiness = 0;

    $scope.categoryBrowser = [];
    userService.getAttraction(attraction_id).then(function(response){
      $scope.attraction = response.data.response[0];
      if($scope.attraction.photo_id != 0){
        $scope.avatar_check = true;
      }
      userService.getPopularCategories().then(function(response){
        $scope.categories = response.data.response;
        $rootScope.mlodzi = {
          categories: response
        };
        angular.forEach( $scope.categories , function( value, key ) {
          if(value.child_categories.length == 0){
            $scope.categoryBrowser.push({ name: '<strong>'+value.name+'</strong>', id:value.id,ticked:false });
          }else{
            $scope.categoryBrowser.push({ name: '<strong>'+value.name+'</strong>', id:value.id, msGroup:true });
          }
          angular.forEach( value.child_categories , function( value, key ) {
              $scope.categoryBrowser.push({ name: '<span style="padding-left:20px;">'+value.name+'</span>', id:value.id,ticked:false });
          });

          if(value.child_categories.length > 0){
            $scope.categoryBrowser.push({  msGroup:false });
          }
        });
        if($scope.attraction != null){
          angular.forEach( $scope.attraction.attraction_categories , function( val, k ) {
            if(typeof val.parent_id != 'null'){
              $scope.categoryBrowserCheck.push({ id:val.category_id, ticked:true, msGroup:false });
            }else{
              $scope.categoryBrowserCheck.push({ id:val.category_id, msGroup:true });
            }
          });
          angular.forEach( $scope.categoryBrowser , function( val, k ) {
            angular.forEach( $scope.categoryBrowserCheck , function( vali, ki ) {
              if(val.id === vali.id && val.msGroup != true){
                $scope.categoryBrowser[k].ticked = true;
              }
            });
          });
        }

        userService.getUserBusinesses().then(function(response) {
          $scope.user_businesess = response;
          $rootScope.loaders.homeCtrl.attraction_edit = 0;
        });

        window.setTimeout(function(){
           $rootScope.loaders.homeCtrl.cat = 0;
        },200);

      });
    });

    $scope.$emit('newPageLoaded', false);

    $scope.editAttraction = function(isValid) {
      $scope.loading = 1;
      if($scope.avatar) {
        $scope.attraction.base64 = $scope.avatar.base64;
        $scope.attraction.base64type = $scope.avatar.filetype;
        $scope.avatar_check = true;
      }
      if($scope.gallery){
         $scope.attraction.gallery = $scope.gallery;
      }
      if (isValid) {
        $rootScope.loaders.homeCtrl.addingBusiness = 1;
        $scope.attraction.business_id = $scope.attraction.business_id;
        $scope.attraction.categories = [];
        angular.forEach( $scope.outputBrowser , function( value, key ) {
            $scope.attraction.categories.push(value.id);
        });
        userService.editAttraction($scope.attraction).then(function(resp) {
          window.location.reload();
        });
      } else
        $scope.errors = true;
        var target = $('body');
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
    }


    $scope.$watch('gallery', function(newValue, oldValue) {
        console.log(newValue);
        if(newValue != oldValue){
          $scope.multiple_change = 1;
          userService.addImage(attraction_id,newValue).then(function(response) {
              if(response.data.response == true){
                    userService.getAttraction(attraction_id).then(function(response){
                      $scope.attraction = response.data.response[0];
                    });
                    $scope.multiple_change = 0;
                    var httpCache = $cacheFactory.get('$http');
                    httpCache.remove(base_path + "businesses.json");
                    httpCache.remove(base_path + "attractions/get/" + $scope.attraction.id + ".json");
              }

          });
        }
    });

    $scope.removeImage = function(id){
      $scope.multiple_change = 1;
      userService.removeImage(id).then(function(response) {
          if(response.data.response == true){
                userService.getAttraction(attraction_id).then(function(response){
                  $scope.attraction = response.data.response[0];
                });
                $scope.multiple_change = 0;
                var httpCache = $cacheFactory.get('$http');
                httpCache.remove(base_path + "businesses.json");
                httpCache.remove(base_path + "attractions/get/" + $scope.attraction.id + ".json");
          }

      });
    }

}]);