CoRobic.controller('AttractionsAddController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", "$timeout", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window, $timeout
) {
$scope.avatar =false;

    $scope.attractionForm = {};
    $scope.categories = {};
    $scope.categoryBrowserCheck = [];
    $scope.multiple_change = false;
    $scope.choosed_photo = null;
    $scope.attraction = {};
    $rootScope.active_tab = 'atrakcje';

    $scope.editorOptions = {
        language: 'pl',
        uiColor: '#000000'
    };
    $scope.step = 1;
    $scope.attr_gallery = [];
    $rootScope.loaders.homeCtrl.addingBusiness = 0;
    $rootScope.loaders.homeCtrl.addingPhotos = 0;
    $rootScope.loaders.homeCtrl.steps = 0;
    $scope.recommended = [];
    $scope.categoryBrowser = [];


    $scope.variantForm = {};
    $scope.variant = {
      add: false
    };
    $scope.variant.recurrent = false;
    $scope.variant.price_type = "za godzinę";
    $rootScope.loaders.homeCtrl.addingBusiness = 0;
    $scope.variant.days = {
      pn: {
        active: false,
        hour: "",
        period: 1,
        day_id: 1
      },
      wt:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 2
      },
      sr:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 3
      },
      cz:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 4
      },
      pt:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 5
      },
      sb:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 6
      },
      nd:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 0
      },
    };

    $scope.step1_error = 0;
    $scope.step2_error = 0;
    $scope.step3_error = 0;
    $scope.step4_error = 0;
    $scope.step5_error = 0;
    $scope.firm = [];


    $scope.changePeriod = function(day, period) {
      $scope.variant.days[day].period = period;
    }

    $scope.changeUnit = function(val) {
      $scope.variant.price_type = val;
    }

    $scope.updateDays = function(day) {
      $scope.variant.days[day].active = !$scope.variant.days[day].active;

      if(!$scope.variant.days[day].active) {
        $scope.variant.days[day].hour = "";
        $scope.variant.days[day].period = 1;
      }
    }


    userService.getPopularCategories().then(function(response){
        $scope.categories = response.data.response;

        angular.forEach( $scope.categories , function( value, key ) {
          if(value.child_categories.length == 0){
            $scope.categoryBrowser.push({ name: '<strong>'+value.name+'</strong>', id:value.id,ticked:false });
          }else{
            $scope.categoryBrowser.push({ name: '<strong>'+value.name+'</strong>', id:value.id, msGroup:true });
          }
          angular.forEach( value.child_categories , function( value, key ) {
              $scope.categoryBrowser.push({ name: '<span style="padding-left:20px;">'+value.name+'</span>', id:value.id,ticked:false });
          });

          if(value.child_categories.length > 0){
            $scope.categoryBrowser.push({  msGroup:false });
          }
        });

        userService.getUserBusinesses().then(function(response) {
          $scope.user_businesess = response.data.response;
          if($scope.user_businesess.length == 0) {
            window.location.href = base_path+'/profil/biznesy/dodaj';
          }
          angular.forEach( $scope.user_businesess , function( value, key ) {
          $scope.firm.push({'name': value.business_name, 'id': value.id, 'ticked': false});
        });
          $rootScope.loaders.homeCtrl.attraction_edit = 0;
        });
    });

    $scope.$watch('firmm', function(newValue, oldValue){
      $scope.attraction.business_id = newValue[0].id;
    });


    $scope.choosePhoto = function(index) {
      $scope.choosed_photo = $scope.recommended[index];
    }


    $scope.fClose = function(){
      if($scope.outputBrowser.length > 0){

        userService.getRecommended($scope.categoryBrowser).then(function(response) {
          $scope.recommended = response.data.response;
        });
      }

    }
    $scope.businessAddress = function(){
      $scope.attraction.street = $scope.user_businesess[0].street;
      $scope.attraction.street_nr = $scope.user_businesess[0].street_nr;
      $scope.attraction.postal = $scope.user_businesess[0].postal;
      $scope.attraction.city = $scope.user_businesess[0].city;
    }
  $scope.localLang = {
      selectNone      : "Odznacz",
      reset           : "ZAPISZ",
      search          : "Wpisz frazę...",
      nothingSelected : "Wybierz"         //default-label is deprecated and replaced with this.
  }
    $scope.nextStep = function(){

      $rootScope.loaders.homeCtrl.steps = 1;
        if($scope.step < 5){

          if($scope.step == 3) {
            if($scope.choosed_photo || $scope.avatar){
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);

              $scope.step = $scope.step + 1;
              $scope.step3_error = 0;
            }else{
              $scope.step3_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

          if($scope.step == 1) {
            if($scope.outputBrowser.length > 0){
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
              $scope.step = $scope.step + 1;
              $scope.step1_error = 0;
              $scope.fClose();
              focus('nazwa_atrakcji');
            }else{
              $scope.step1_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

          if($scope.step == 2) {
            if(typeof $scope.attraction != 'undefined' && $scope.attraction.name){
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
              $scope.step = $scope.step + 1;
              $scope.step2_error = 0;
            }else{
              $scope.step2_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

          if($scope.step == 4) {
            if($scope.attraction.street && $scope.attraction.street_nr && $scope.attraction.postal && $scope.attraction.city){
              var target = $('#attr_form');

              $('html,body').animate({
                scrollTop: target.offset().top
              }, 200);
              $scope.step = $scope.step + 1;
              $scope.step4_error = 0;
            }else{
              $scope.step4_error = 1;
            }
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }





        }


    }
    $scope.stepCheck = function(step){
      if(step <  $scope.step){
        $rootScope.loaders.homeCtrl.steps = 1;
          if(step <= 5 && step >= 1){
            $scope.step = step;
            if($scope.step == 2) {
              $scope.fClose();
            }
          }
        $timeout(function(){
          $rootScope.loaders.homeCtrl.steps = 0;
        },500);
      }
    }

    $scope.prevStep = function(){

      $rootScope.loaders.homeCtrl.steps = 1;
        if($scope.step > 1){
          $scope.step = $scope.step - 1;
          if($scope.step == 2) {
            $scope.fClose();
          }
        }
        $timeout(function(){
          $rootScope.loaders.homeCtrl.steps = 0;
        },500);

    }

    $scope.$emit('newPageLoaded', false);

    $scope.$watch('gallery', function(newValue, oldValue) {
      if(newValue != oldValue){
        $scope.attr_gallery.push(newValue[0]);
      }
    });
    $scope.$watch('avatar', function(newValue, oldValue) {
      if(newValue != oldValue){
        $scope.choosed_photo = null;

      }
    });
    $scope.removeImage = function(id) {
      $scope.gallery.splice(id, 1);
    }

    $scope.addAttraction = function(isValid) {
      $scope.loading = 1;

      if($scope.step == 5) {
        if(!$scope.variant.add){

          if($scope.variant.age_from && $scope.variant.age_to) {
            $scope.step5_error = 0;
          }else{
            $scope.step5_error = 1;
            $timeout(function(){
              $rootScope.loaders.homeCtrl.steps = 0;
            },500);
            return;
          }

        }else{

          $scope.step5_error = 0;
        }

      }

      if(  $scope.step5_error == 0) {

      if($scope.avatar && $scope.choosed_photo == null) {
        $scope.attraction.base64 = $scope.avatar.base64;
        $scope.attraction.base64type = $scope.avatar.filetype;
      }

      if($scope.choosed_photo != null) {
        $scope.attraction.choosed_photo = $scope.choosed_photo;
      }

      if($scope.gallery){
         $scope.attraction.gallery = $scope.attr_gallery;
      }
      if (isValid) {
          $rootScope.loaders.homeCtrl.addingBusiness = 1;
          
          $scope.attraction.categories = [];
          angular.forEach( $scope.categoryBrowser , function( value, key ) {
            if(typeof value.ticked != 'undefined' && value.ticked == true){
              $scope.attraction.categories.push(value.id);
            }

          });

          userService.saveAttraction($scope.attraction).then(function(resp) {

            $scope.variant.attraction_id = resp.data.response;
            if($scope.variant.add == false) {
              userService.saveVariant($scope.variant).then(function(resp) {
                var httpCache = $cacheFactory.get('$http');
                httpCache.remove(base_path + "attractions/get/" +  $scope.variant.attraction_id + ".json");
                window.location.href = base_path+'/profil/atrakcje';
                $rootScope.loaders.homeCtrl.addingBusiness = 0;

                httpCache.remove(base_path + "businesses.json");
                $scope.loading = 0;
                // $location.path("/profil/biznesy");
                if($rootScope.authentication.userProfile.standard_bundle == true) {
                    swal("Sukces!", "Nowa atrakcja została dodana.", "success");
                }else{
                    swal("Sukces!", "Nowa atrakcja została dodana", "success");
                }

              });
            }else{
              var httpCache = $cacheFactory.get('$http');
              httpCache.remove(base_path + "attractions/get/" +  $scope.variant.attraction_id + ".json");
              window.location.href = base_path+'/profil/atrakcje';
              $rootScope.loaders.homeCtrl.addingBusiness = 0;
              httpCache.remove(base_path + "businesses.json");
              $scope.loading = 0;
              if($rootScope.authentication.userProfile.standard_bundle == true) {
                  swal("Sukces!", "Nowa atrakcja została dodana.", "success");
              }else{
                  swal("Sukces!", "Nowa atrakcja została dodana", "success");
              }
            
            }

          });
      } else {
        $scope.errors = true;
        var target = $('body');
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
      }
    }


    }
}]);