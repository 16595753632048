CoRobic.service('reviewsService', ['$http', function($http) {

    this.createReview = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'reviews/create.json',
            data: data
        });
    }


}]);