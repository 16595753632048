CoRobic.controller('ContactController', ['$scope', '$rootScope', 'contactService', '$location', function($scope, $rootScope, contactService, $location) {
     $scope.contactForm = {};
    $scope.message = {};
    $scope.errors = false;
    $scope.sent = false;


    $rootScope.loaders.homeCtrl.contact = 0;
    $scope.$emit('newPageLoaded', false);



    $scope.submitForm = function(isValid) {
      if (isValid) {
        $rootScope.loaders.homeCtrl.sendingContact = 1;
        $scope.errors = false;
        contactService.sendMessage($scope.message).then(function() {
          $scope.sent = true;
          $scope.message = {};
          $scope.contactForm.$setPristine();
          $rootScope.loaders.homeCtrl.sendingContact = 0;
        });
      } else
        $scope.errors = true;
    }
}])