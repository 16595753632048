CoRobic.service('appService', ['$http', function($http) {

    this.verifiedOnly = function(city) {
        return $http({
            method: 'GET',
            url: base_path+'home/verifiedOnly/'+city
        });
     }

     this.setSession = function(name, value) {
        return $http({
            method: 'POST',
            url: base_path+'home/setSession.json',
            data: {
                name: name,
                value: value
            }
        });
     }

     this.fblogin = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'users/fblogin.json',
            data: data
        });
     }

     this.remind = function(email) {
        return $http({
            method: 'POST',
            url: base_path + 'users/remind.json',
            data: {
              email: email
            }
        });
     }

}]);