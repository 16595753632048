CoRobic.directive('backImg', ['$timeout', function ($timeout){
    return function(scope, element, attrs){
            var url = attrs.backImg;
            attrs.$observe('backImg', function(value){
                element.css({
                    'background-image': 'url(' + value +')',
                    'background-size' : 'cover'
                });
            });
    };
}]);


CoRobic.directive('customScrollbar', ['$parse', '$timeout', function($parse, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, elm, attrs) {
        $timeout(function(){
            elm.mCustomScrollbar();
        },1000);
      }
    };
}]);



CoRobic.directive('lightbox', ['$parse', '$timeout', function($parse, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, elm, attrs){
        $timeout(function(){
            console.log('nivo');
            elm.nivoLightbox( {
                effect: 'fadeScale',
            });
        });
        }
    };
}]);


CoRobic.directive('backButton', [ function() {
    return {
      restrict: 'A',
      link: function(scope, elem, attrs) {
        elem.bind('click', function() {

          scope.window = window;

          scope.window.history.back();
        });
      }
    };
}]);

CoRobic.directive('mask', ["$parse", "$filter", "$timeout",  function($parse, $filter, $timeout){
    function setCaretPosition(elem, caretPos) {
        if (elem !== null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            } else {
                if (elem.setSelectionRange) {

                    elem.setSelectionRange(caretPos, caretPos);
                }

            }
        }
    }

    return {
        link: function($scope, elem, attrs) {
            $timeout(function() {
               if(elem.val().length == 0){
                 setCaretPosition(elem, 0);
                }
               var ngmodel = $parse(attrs.ngModel);
               elem.mask(attrs.mask, {autoclear: false, completed: function(){ ngmodel.assign($scope, elem.val()); }});

            });
        }
    }
  }

]);

CoRobic.directive('datetimepicker', ["$parse", "$timeout", function($parse, $timeout) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ngModel){
        $timeout(function(){
          element.datetimepicker({
            locale: 'pl',
            format: 'YYYY-MM-DD HH:mm'
          }).on('dp.change', function (e) {
               ngModel.$setViewValue(e.date.format('YYYY-MM-DD HH:mm'));
               scope.$apply();
          });
        }, 1000);
      }
    };
}])