CoRobic.service('searchService', ['$http', function($http) {

    this.getAttractions = function(params) {
        return $http({
            method: 'GET',
            url: base_path+'search/get.json',
            params: {
                date_from: params.date_from,
                date_to: params.date_to,
                price_from: params.price_from,
                price_to: params.price_to,
                q:params.q,
                c:params.c,
                query: params.query,
                'cat[]':params.cat,
                'age[]':params.age,
                page: params.page,
                new:params.isnew,
                promotion: params.promotion,
                bundle: params.bundle,
                limit: params.limit,
                sort: params.sort
            }
        });
     }

     this.getCategories = function() {
        return $http({
            method: 'GET',
            url: base_path+'categories/popular.json',
        });
     }

     this.getCities = function() {
         return $http({
            method: 'GET',
            url: base_path+'cities/index.json',
        });
     }


}]);