CoRobic.controller('ProfileEditController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window
) {
var httpCache = $cacheFactory.get('$http');
    $scope.profileForm = {};
    $scope.name_error = false;
    $rootScope.seo_title = 'Profil - corobić.pl';
    $rootScope.loaders.homeCtrl.addingBusiness = 0;
    $scope.name_error = $routeParams.err;
    $scope.user = angular.copy($rootScope.authentication.userProfile);
    $rootScope.loaders.homeCtrl.cat = 0;
    $rootScope.loaders.homeCtrl.profile = 0;


    $scope.active_tab = "profil";

    $scope.$emit('newPageLoaded', false);

    $scope.editProfile = function(isValid) {

      if($scope.avatar) {
        $scope.user.base64 = $scope.avatar.base64;
        $scope.user.base64type = $scope.avatar.filetype;
      }
      if (isValid) {
        $rootScope.loaders.homeCtrl.addingBusiness = 1;
        userService.saveUserData($scope.user).then(function(resp) {
          $rootScope.loaders.homeCtrl.addingBusiness = 0;
          window.location.reload();

        });
      } else
        $scope.errors = true;
    }
}]);