

	CoRobic.filter('range', [function (){
	  return function(input, total) {
            total = parseInt(total);

            for (var i=0; i<total; i++) {
            input.push(i);
            }

            return input;
        }
	}]);

  
    CoRobic.filter('customLimitTo', [function (){
        return function (input, limit, tail) {
        if (input.length <= limit) return input;

        var result = input.substr(0, limit) + (tail || '…');

        var result = input.substr(0, limit) + (tail || ' …');

        return result;
        };
    }]);
    
    CoRobic.filter('escapeHtml', ['$sce',function ($sce){
        return function(input) {
            return $sce.getTrustedHtml($sce.trustAsHtml(input));
        }
        }
    ]);

    CoRobic.filter('htmlToPlainText', [function (){
        return function(text) {
            return  text ? String(text).replace(/(<([^>]+)>)/ig,""): ' ';
        };
    }]);