CoRobic.service('contactService', ['$http', function($http) {


     this.sendMessage = function(data) {
        return $http({
            method: 'POST',
            url: base_path+'static/contact.json',
            data: data
        });
     }



}]);