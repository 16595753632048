CoRobic.controller('AttractionController', ["$scope", "$rootScope", "$timeout","NgMap", "ngDialog", "$filter", "$location", '$anchorScroll', '$http', function(
    $scope, $rootScope, $timeout, NgMap, ngDialog, $filter, $location, $anchorScroll, $http
){
    $scope.attraction = false;

    $scope.init = function(attraction) {
        $scope.attraction = attraction;
    }

    NgMap.getMap().then(function(map) {
        $timeout(function() {
          google.maps.event.trigger(map, 'resize');
          var myLatlng = new google.maps.LatLng(parseFloat($scope.attraction.lat), parseFloat($scope.attraction.lng));
          map.setCenter(myLatlng);

          var nr = $scope.attraction.street_nr;
          if($scope.attraction.appartment_nr)
            nr += "/" + $scope.attraction.appartment_nr;
          var contentString = '<h2 style="font-weight: 700; font-size: 18px; padding-top:10px;">' + $scope.attraction.name + '</h2><div id="bodyContent"><p>' + $scope.attraction.street + ', '+$scope.attraction.street_nr+'</p></div>';

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          infowindow.open(map, map.markers.foo);
        }, 1000);

        google.maps.event.addListener(map, 'click', function(event) {
          this.setOptions({
            scrollwheel: true
          });
        });

      });

      $scope.$on('reviewadd', function(event, data) {

            window.location.reload();

      });


      $scope.goToReservation = function(){
      $('html, body').animate({
        scrollTop: $("#reserve").offset().top
    }, 2000);
    }

      $scope.openContactModal = function() {
        ngDialog.open({
          template: 'contactModal',
          className: 'ngdialog-theme-default login-modal',
          scope: $scope
        });
      };

      $scope.openContactEmailModal = function() {
        ngDialog.open({
          template: 'contactMailModal',
          className: 'ngdialog-theme-default login-modal',
          scope: $scope
        });
      };

      $scope.openReservationModal = function() {
        
            ngDialog.open({
            template: 'reservationModal',
            className: 'ngdialog-theme-default login-modal',
            scope: $scope
          });
        
        
      };


      $scope.reserve = function() {
        if($rootScope.authentication.userProfile) {
        $http({
          method: 'POST',
          url: base_path+'/reservations/save',
          data: { user_first_name: $('[name="resr_name"]').val(), user_email:$('[name="resr_email"]').val(), user_phone_number: $('[name="resr_phone_number"]').val(), variant_id: $scope.special_variant, user_id:$rootScope.authentication.userProfile.id}
        }).then(function (response) {

          ngDialog.closeAll();
          window.location.reload();

        }, function(response) {
          alert("Wystąpił błąd podczas zapisywania rezerwacji.");
        });
        }else{
          alert('Rezerwacji można dokonać tylko jako zalogowany użytkownik.')
        }
      }
}])