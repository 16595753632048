var CoRobic = angular.module('CoRobic',[
  'ngSanitize',
  'ngDialog',
  'facebook',
  'angularMoment',
  'ngRoute',
  'slugifier',
  'isteven-multi-select',
  'bw.paging',
  'ngMap',
  'angular-bind-html-compile',
  'naif.base64',
  'textAngular'
]);


CoRobic.config(['FacebookProvider', '$locationProvider', '$provide', function(FacebookProvider, $locationProvider, $provide) {
    FacebookProvider.init('1065328836834390');
    // $locationProvider.html5Mode(true).hashPrefix('!');
    // $locationProvider.html5Mode({
    //     enabled:true,
    //     requireBase: false
    // });

    $provide.decorator('taOptions', ['$delegate', function(taOptions){
        // $delegate is the taOptions we are decorating
        // here we override the default toolbars and classes specified in taOptions.
        taOptions.forceTextAngularSanitize = true; // set false to allow the textAngular-sanitize provider to be replaced
        taOptions.keyMappings = []; // allow customizable keyMappings for specialized key boards or languages
        taOptions.toolbar = [
            [],
            ['bold', 'italics', 'underline', 'ul', 'ol', 'redo', 'undo', 'clear'],
            [],
            ['html']
        ];
        taOptions.classes = {
            focussed: 'focussed',
            toolbar: 'btn-toolbar',
            toolbarGroup: 'btn-group',
            toolbarButton: 'btn btn-default',
            toolbarButtonActive: 'active',
            disabled: 'disabled',
            textEditor: 'form-control',
            htmlEditor: 'form-control'
        };
        return taOptions; // whatever you return will be the taOptions
    }]);
}]);

CoRobic.run(['amMoment', '$rootScope', function(amMoment, $rootScope) {
    amMoment.changeLocale('pl');

    $rootScope.daysNames = {
      pn: "Poniedziałek",
      wt: "Wtorek",
      sr: "Środa",
      cz: "Czwartek",
      pt: "Piątek",
      sb: "Sobota",
      nd: "Niedziela"
    };

    $rootScope.periods = {
      1: "Co tydzień",
      2: "Co dwa tygodnie",
      4: "Co miesiąc"
    };

    $rootScope.loaders = {
      homeCtrl: {
        latest: 0,
        cat: 0,
        login: 0,
        search: 0,
        attraction: 0,
        business: 0,
        overlay: 0,
        attraction_edit: 0,
        variant: 0,
        businesses: 0,
        profile: 0,
        attraction_view: 0,
        contact: 0,
        static: 0,
        changePassword: 0,
        myReservations: 0,
        childrenList: 0,
        reviews: 0,
        searchResults: 0,
        recover: 0,
        addingReview: 0,
        sendingContact: 0,
        registering: 0,
        reminding: 0,
        categories: 0
      }
    };
}]);