CoRobic.controller('ReviewsController', ["$scope", "$rootScope", "reviewsService", "$location", '$route', "$cacheFactory", "ngDialog", '$anchorScroll', function(
    $scope, $rootScope, reviewsService, $location, $route, $cacheFactory, ngDialog, $anchorScroll
){
    $scope.submitReview = function() {

        if($rootScope.loaders.homeCtrl.addingReview == 0) {
            $rootScope.loaders.homeCtrl.addingReview = 1;
            
            var data = {
                business_id: $rootScope.review_data.bizid,
                attraction_id: $rootScope.review_data.attraction_id,
                madeby: $rootScope.authUser.id,
                rate: $rootScope.review_data.real_count,
                comment: $rootScope.review_data.content
            };
    
            reviewsService.createReview(data).then(function(response) {
    
                var data = response;
    
                data.user = {
                    first_name: $rootScope.authUser.first_name,
                    last_name: $rootScope.authUser.last_name,
                    email: $rootScope.authUser.email,
                    user_photo: $rootScope.authUser.user_photo
                }
    
                $rootScope.loaders.homeCtrl.addingReview = 0;
                ngDialog.close();
                var httpCache = $cacheFactory.get('$http');
    
                if(data.attraction_id !== 0)
                httpCache.remove(api_url + "attractions/show/" + $rootScope.review_data.slug + ".json");
                else
                httpCache.remove(api_url+ "businesses/show/" + $rootScope.review_data.slug + ".json");
    
                window.location.reload();
    
            });
        }

    };
}]);