CoRobic.controller('AttractionVariantsAddController', ["$scope", "$rootScope", "$location", "$cacheFactory", "ngDialog", "userService", "$routeParams", "$window", function(
$scope, $rootScope, $location, $cacheFactory, ngDialog, userService,$routeParams, $window
) {
 $scope.variantForm = {};
    $scope.variant = {};
    $rootScope.loaders.homeCtrl.variant = true;
    $scope.variant.recurrent = false;
    $scope.variant.price_type = "za godzinę";
    $rootScope.loaders.homeCtrl.addingBusiness = 0;
    $scope.variant.days = {
      pn: {
        active: false,
        hour: "",
        period: 1,
        day_id: 1
      },
      wt:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 2
      },
      sr:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 3
      },
      cz:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 4
      },
      pt:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 5
      },
      sb:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 6
      },
      nd:  {
        active: false,
        hour: "",
        period: 1,
        day_id: 0
      },
    };

    $scope.changePeriod = function(day, period) {
      $scope.variant.days[day].period = period;
    }

    $scope.changeUnit = function(val) {
      $scope.variant.price_type = val;
    }

    $scope.updateDays = function(day) {
      $scope.variant.days[day].active = !$scope.variant.days[day].active;

      if(!$scope.variant.days[day].active) {
        $scope.variant.days[day].hour = "";
        $scope.variant.days[day].period = 1;
      }
    }

    $rootScope.seo_title = 'Warianty - corobić.pl';
    $scope.variant.attraction_id = attraction_id;

    userService.getUserBusinesses().then(function(response) {
      $scope.user_businesess = response.data.response;

      userService.getAttraction($scope.variant.attraction_id).then(function(response) {
        $scope.attraction = response.data.response[0];
        $rootScope.loaders.homeCtrl.variant = 0;
      });
    });

    $scope.$emit('newPageLoaded', false);
    $scope.isDisabledDate = function(currentDate, mode) {
      return mode === 'day' && (currentDate.getDay() === 0 || currentDate.getDay() === 6);
    };
    $scope.addVariant = function(isValid) {
      if (isValid) {
          $rootScope.loaders.homeCtrl.addingBusiness = 1;

          userService.saveVariant($scope.variant).then(function(resp) {
            window.location.href = base_path + '/profil/atrakcja/'+ $scope.variant.attraction_id+'/terminy';
            $rootScope.loaders.homeCtrl.addingBusiness = 0;
            var httpCache = $cacheFactory.get('$http');
            httpCache.remove(main_config.api.url + "attractions/get/" +  $scope.variant.attraction_id + ".json");
            $location.path("/profil/atrakcje/zobacz/" +  $scope.variant.attraction_id);
            swal("Sukces!", "Nowy termin został dodany", "success");
          });
      } else {
        $scope.errors = true;
        var target = $('body');
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
      }
    }
}]);