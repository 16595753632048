  CoRobic.controller('SearchController', [
    "amMoment",
    "searchService",
    "$routeParams",
    "$scope",
    "$rootScope",
    "$route",
    "$timeout",
    "$anchorScroll",
    "$location",
    "ngDialog",
    "Slug"
    ,function(
        amMoment,
        searchService,
        $routeParams,
        $scope,
        $rootScope,
        $route,
        $timeout,
        $anchorScroll,
        $location,
        ngDialog,
        Slug) {


    $scope.Math = Math;
    $rootScope.path  = $location.path();

    $scope.pages = [];
    $scope.params = [];
    $scope.params.limit = 20;

    if(typeof $location.$$search.page == 'undefined') {
      $scope.params.page = 1; $location.search('page', $scope.params.page);
    }





    $scope.categorySelection = [];
    $scope.is_city = false;
    $rootScope.search.ageSelection = [];
    $rootScope.search.ageSelectionSecond = [];
    $rootScope.search.catSelection = [];
    $scope.choosen = '';
    $scope.loading = true;

    $scope.init = function(data) {
      console.log('init', data);
      if(data.category){
        $scope.parent = data.category;
      }
      if(city) {
        $scope.is_city = true;
        $scope.search.c = city;
        $location.$$search.c = $scope.search.c;
        $scope.params.c = $scope.search.c;
      }
    }


    /* RESOLVING */

    $scope.params.q = $location.$$search.q;
    // $scope.params.c = $location.$$search.c;
    $scope.params.new = $location.$$search.new;
    $scope.params.promotion = $location.$$search.promotion;
    $scope.params.bundle = $location.$$search.bundle;
    $scope.params.cat = $location.$$search.cat;
    $scope.params.age = $location.$$search.age;
    $scope.params.price_from = $location.$$search.price_from;
    $scope.params.price_to = $location.$$search.price_to;
    $scope.params.date_from = $location.$$search.date_from;
    $scope.params.date_to = $location.$$search.date_to;
    $scope.params.query = $location.$$search.query;
    $scope.params.page = $location.$$search.page;
    $scope.params.sort = $location.$$search.sort;
    $scope.parent = $location.$$search.parent;
    $scope.parent_name = '';
    $scope.child = $location.$$search.child;

    $location.search('page', $scope.params.page);

    if(typeof $scope.params.sort  == 'undefined') {
      $scope.params.sort = 'newest';
      $scope.sort_text = 'Najnowsze';
    }else{
      if($scope.params.sort == 'newest') {
        $scope.sort_text = 'Najnowsze';
      }else{
        $scope.sort_text = 'Najlepiej oceniane';
      }
    }

    $scope.more = false;
    $scope.moreLessText = 'Pokaż więcej';
    $scope.moreLess = function(){
      if($scope.more){
        $scope.more = false;
        $scope.moreLessText = 'Pokaż więcej';
      }else{
        $scope.more = true;
        $scope.moreLessText = 'Pokaż mniej';
      }
    }


    if(typeof $scope.parent == 'undefined') {
      $rootScope.noindex = true;
    }else{
      $rootScope.noindex = false;
    }

    /* RESOLVING */
    $scope.search_text = '';


    $scope.check_search_text = function(){
      $scope.search_text = '';
      if(typeof $rootScope.search.catSelection != 'undefined' && $rootScope.search.catSelection.length == 1 && $scope.search_text =='') {
        $scope.search_text = 'Kategoria: <strong>'+$scope.choosen+', </strong>';
        if($scope.real_count > 4) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcji.</strong>';
        }
        if($scope.real_count <= 4 && $scope.real_count > 0) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcje.</strong>';
        }
        if($scope.real_count == 0) {
          $scope.search_text += '<strong>nie znaleziono atrakcji.</strong>';
        }
      }

      if(typeof $rootScope.search.c != 'undefined' && typeof $scope.is_city != 'undefined' && $rootScope.search.c.length > 0 && $scope.is_city == true && $scope.search_text =='') {
        $scope.search_text = 'Miasto: <strong>'+$rootScope.search.c+', </strong>';
        if($scope.real_count > 4) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcji.</strong>';
        }
        if($scope.real_count <= 4 && $scope.real_count > 0) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcje.</strong>';
        }
        if($scope.real_count == 0) {
          $scope.search_text += '<strong>nie znaleziono atrakcji.</strong>';
        }
      }
      if(typeof $rootScope.search.q != 'undefined' && typeof $scope.is_city != 'undefined' && $rootScope.search.q.length > 0 && $scope.is_city == false && $scope.search_text =='') {
        $scope.search_text = 'Wyszukiwałeś: <strong>'+$rootScope.search.q+', </strong>';
        if($scope.real_count > 4) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcji.</strong>';
        }
        if($scope.real_count <= 4 && $scope.real_count > 0) {
          $scope.search_text += 'znaleziono: <strong>'+$scope.real_count+' atrakcje.</strong>';
        }
        if($scope.real_count == 0) {
          $scope.search_text += '<strong>nie znaleziono atrakcji.</strong>';
        }
      }

      if(typeof $scope.real_count != 'undefined' && $scope.real_count > 4 && $scope.search_text =='') {
        $scope.search_text = 'Znaleziono: <strong>'+$scope.real_count+' atrakcji.</strong>';
      }

      if(typeof $scope.real_count != 'undefined' && $scope.real_count == 0 && $scope.search_text =='') {
        $scope.search_text = '<strong>Nie znaleziono atrakcji.</strong>.';
      }
      if(typeof $scope.real_count != 'undefined' && $scope.real_count <= 4 && $scope.real_count > 0 && $scope.search_text =='') {
        $scope.search_text = 'Znaleziono: <strong>'+$scope.real_count+' atrakcje.</strong>'
      }
    }


    searchService.getCategories().then(function(response){
        $rootScope.mlodzi = {
          categories: response.data.response,
          catclick: false,
        };
        $scope.categories = response.data.response;
        angular.forEach( $scope.categories , function( value, key ) {
          value.subs = false;

        });
         angular.forEach( $scope.categories , function( value, key ) {

            angular.forEach( $rootScope.search.catSelection , function( v, ki ) {
              if(value.id == v){
                $scope.choosen = value.name;
              }
            });

            angular.forEach( value.child_categories , function( va, key ) {

              angular.forEach( $rootScope.search.catSelection , function( vi, kii ) {
                if(va.id == vi){
                  $scope.choosen = va.name;
                }
              });

            });

        });

        if(typeof $scope.parent != 'undefined') {

          angular.forEach( $scope.categories , function( value, key ) {
            if(typeof value != 'undefined') {
            var value_name_slug = value.name;
                if($scope.parent == value_name_slug){
                  $scope.parent_name = value.name;
                  $scope.choosen = value.name;
                  $rootScope.search.catSelection.push(value.id);


                  angular.forEach( $rootScope.search.catSelection , function( v, ki ) {
                    if(value.id == v){

                      $scope.categories[key].subs = true;
                    }
                  });
                }


            angular.forEach( value.child_categories , function( va, key ) {
                if(typeof va != 'undefined') {
                var value_name_slug2 = va.name;
                if($scope.parent == value_name_slug2){
                  $rootScope.search.catSelection.push(va.id);
                  $scope.choosen = va.name;
                  $scope.parent_name = va.name;

                  angular.forEach( $rootScope.search.catSelection , function( vi, kii ) {
                    if(va.id == vi){

                      $scope.categories[key].subs = true;
                    }
                  });
                }
              }
              });
            }
          });


        }


        $timeout(function() {
          console.log('city select');
          angular.forEach( $scope.citiesBrowser , function( value, key ) {
            $timeout(function(){
              if(typeof value != 'undefined') {
                if($scope.search.c == value.name){
                    $scope.citiesBrowser[key].ticked = true;
                }else{
                    $scope.citiesBrowser[key].ticked = false;
                }
              }
              $scope.$apply();
            },100);
          });
        },1000);


        $scope.params.cat = $rootScope.search.catSelection;



        searchService.getAttractions({
          q: $location.$$search.q,
          c: $location.$$search.c,
          query: $location.$$search.query,
          new: $location.$$search.new,
          promotion: $location.$$search.promotion,
          bundle: $location.$$search.bundle,
          cat: $scope.params.cat,
          age: $location.$$search.age,
          price_from: $location.$$search.price_from,
          price_to: $location.$$search.price_to,
          date_from: $location.$$search.date_from,
          date_to: $location.$$search.date_to,
          limit: $scope.params.limit,
          sort: $scope.params.sort,
          page: $scope.params.page
        }).then(function(response){

          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.real_count = response.data.response.real_count;
          $scope.is_city = response.data.response.is_city;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.search = 0;
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;

        });

        $rootScope.loaders.homeCtrl.cat = 0;
    });





    $scope.citiesBrowser = [];
    searchService.getCities().then(function(response){
        $scope.cities = response.data.response;
        angular.forEach( $scope.cities , function( value, key ) {
          $timeout(function(){
            $scope.$apply();
            if($scope.search.c == value && $scope.is_city == true){
              $scope.citiesBrowser.push({ name: value, ticked:true });
            }else{
              $scope.citiesBrowser.push({ name: value, ticked:false });
            }
          },500);

        });
    });



    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(mm<10) {
        mm='0'+mm;
    }

    $scope.scrollbarConfig = {
      autoResize: false, // If true, will listen for DOM elements being added or removed inside the scroll container
      direction: 'vertical', // The direction of the scrollbar
      scrollbar: {
          width: 6, // Width (thickness. Is actually height on horizontal scrollbars) of the scrollbar
          hoverWidth: 8, // Width on scrollbar hover
          color: 'rgba(0,0,0, .6)', // Background color of the scrollbar
          show: false // If true, scrollbar will always be visible
      },
      scrollbarContainer: {
          width: 12, // Width of the container surrounding the scrollbar. Becomes visible on hover
          color: 'rgba(0,0,0, .1)' // Background color of the scrollbar container
      },
      scrollTo: null // Scroll to the 'start' or 'end' on initialization and content changes. Pixel values may also be given.
    }


    $scope.today = dd + "." + mm + "." + yyyy;

    $scope.$watchCollection('categories', function(newValue, oldValue) {
        angular.forEach( $scope.categories , function( value, key ) {
              if($rootScope.search.catSelection[0] == value.id){
              if(typeof value != 'undefined') {
                $scope.choosen = value.name;
                $rootScope.seo_title = $scope.choosen + ' - corobić.pl';
              }
              }
              if(typeof value.child_categories != 'undefined') {
              angular.forEach( value.child_categories , function( val, k ) {

                    if($rootScope.search.catSelection[0] == val.id){
                    if(typeof val != 'undefined') {
                      $scope.choosen = val.name;
                      $rootScope.seo_title = $scope.choosen + ' - corobić.pl';
                    }
                    }
                    angular.forEach( $rootScope.search.catSelection , function( v, ki ) {
                    if(val.id == v){
                      $scope.categories[key].subs = true;
                    }
                  });

              });
            }
        });
    });

    /* END OF RESOLVING */

    if (Object.prototype.toString.call($location.$$search.age) === '[object Array]') {
      for (var i = 0; i < $location.$$search.age.length; i++) {
        $rootScope.search.ageSelection[i] = parseInt($location.$$search.age[i]);
      }
    } else {
      if (!isNaN($location.$$search.age)) {
        $rootScope.search.ageSelection[0] = parseInt($location.$$search.age);
      }
    }

    if (Object.prototype.toString.call($location.$$search.cat) === '[object Array]') {
      for (var i = 0; i < $location.$$search.cat.length; i++) {
        $scope.search.catSelection[i] = parseInt($location.$$search.cat[i]);
      }
    } else {
      if (!isNaN($location.$$search.cat)) {
        $scope.search.catSelection[0] = parseInt($location.$$search.cat);
      }
    }

    if (!isNaN($location.$$search.promotion)) {
      $scope.promotion = true;
    }
    if (!isNaN($location.$$search.bundle)) {
      $scope.bundle = true;
    }
    if (!isNaN($location.$$search.new)) {
      $scope.isnew = true;
    }
    if ($location.$$search.q != 'undefined') {
      $rootScope.search.q = $location.$$search.q;
    }
    if ($location.$$search.c != 'undefined') {
      $rootScope.search.c = $location.$$search.c;
    }
    if ($location.$$search.query != 'undefined') {
      $rootScope.search.query = $location.$$search.query;
    }
    if ($location.$$search.date_from != 'undefined') {
      $rootScope.search.date_from = $location.$$search.date_from;
    }
    if ($location.$$search.date_to != 'undefined') {
      $rootScope.search.date_to = $location.$$search.date_to;
    }
    if ($location.$$search.price_from != 'undefined') {
      $rootScope.search.price_from = $location.$$search.price_from;
    }
    if ($location.$$search.price_to != 'undefined') {
      $rootScope.search.price_to = $location.$$search.price_to;
    }

    $scope.$watchCollection('search.catSelection', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;

        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.cat = newValue;

        if(typeof $rootScope.search.catSelection != 'undefined' && $rootScope.search.catSelection.length) {
          angular.forEach( $scope.categories , function( value, key ) {
                if($rootScope.search.catSelection[0] == value.id){
                  if(typeof value != 'undefined') {
                    $scope.choosen = value.name;
                    $rootScope.seo_title = $scope.choosen + ' - corobić.pl';
                    if($scope.categories[key].subs == false){

                      $scope.categories[key].subs = true;
                    }
                  }
                }

                if(typeof value.child_categories != 'undefined') {
                  angular.forEach( value.child_categories , function( val, k ) {

                        if($rootScope.search.catSelection[0] == val.id){
                        if(typeof val != 'undefined') {
                          $scope.choosen = val.name;
                          $rootScope.seo_title = $scope.choosen + ' - corobić.pl';
                        }
                        }
                        angular.forEach( $rootScope.search.catSelection , function( v, ki ) {
                        if(val.id == v){

                          $scope.categories[key].subs = true;
                        }
                      });

                  });
                }


          });
        }

        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.real_count = response.data.response.real_count;
          $scope.is_city = response.data.response.is_city;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });
    $scope.$watchCollection('search.ageSelection', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.age = newValue;
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });

    $scope.$watch('search.q', function(newValue, oldValue) {
      $scope.q_check = true;
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.q = newValue;
        $location.search('q', newValue);
        searchService.getAttractions($scope.params).then(function(response) {

          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          if($scope.is_city == true) {

            // angular.forEach( $scope.citiesBrowser , function( value, key ) {
            //   $timeout(function(){
            //     if(typeof value != 'undefined') {
            //     if($scope.search.q == value.name){
            //         $scope.citiesBrowser[key].ticked = true;
            //     }else{
            //         $scope.citiesBrowser[key].ticked = false;
            //     }
            //     }
            //     $scope.$apply();
            //   },100);
            // });
          }
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
          $timeout(function(){
              $scope.q_check = false;
          });
        });
      }


    });


        $scope.$watch('search.cit', function(newValue, oldValue) {
          if (newValue != oldValue) {
            if(typeof newValue[0] != 'undefined') {
            $rootScope.search.c = newValue[0].name;
            }
          }
        });
    $scope.$watch('search.c', function(newValue, oldValue) {
      $scope.q_check = true;
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.c = newValue;
        $location.search('c', newValue);
        searchService.getAttractions($scope.params).then(function(response) {

          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          if($scope.is_city == true) {

            angular.forEach( $scope.citiesBrowser , function( value, key ) {
              $timeout(function(){
                if(typeof value != 'undefined') {
                  if($scope.search.c == value.name){
                      $scope.citiesBrowser[key].ticked = true;
                  }else{
                      $scope.citiesBrowser[key].ticked = false;
                  }
                }
                $scope.$apply();
              },100);
            });
          }
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
          $timeout(function(){
              $scope.q_check = false;
          });
        });
      }


    });
    $scope.$watch('cityOutput', function(newValue, oldValue) {
      if (newValue != oldValue && typeof newValue[0] != 'undefined' && typeof newValue[0] != 'undefined') {
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.q = newValue[0].name;
        $scope.search.q = newValue[0].name;
        $location.search('q', newValue[0].name);
      }
    });




    $scope.$watch('search.query', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.query = newValue;
        $location.search('query', newValue);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });

    $scope.$watch('params.sort', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $location.search('sort', newValue);
        if(newValue == 'newest') {
          $scope.sort_text = 'Najnowsze';
        }else{
          $scope.sort_text = 'Najlepiej oceniane';
        }
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
        });
      }
    });

    $scope.$on('$routeUpdate', function(next, current) {
      if(typeof $location.$$search != 'undefined' && typeof $location.$$search.page != 'undefined') {
        console.log($location.$$search.page);
        $scope.changePage($location.$$search.page);
      }else{
        $scope.changePage(1);
      }

    });

    $scope.$watch('search.date_from', function(newValue, oldValue) {
      if (newValue != oldValue && newValue != '') {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);

        $scope.params.date_from = newValue;
        $location.search('date_from', newValue);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });



    $scope.$watch('search.date_to', function(newValue, oldValue) {
      if (newValue != oldValue && newValue != '') {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.date_to = newValue;
        $location.search('date_to', newValue);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });
    $scope.$watch('search.price_from', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.price_from = newValue;
        $location.search('price_from', newValue);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });
    $scope.$watch('search.price_to', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $scope.params.price_to = newValue;
        $location.search('price_to', newValue);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });

    $scope.$watch('isnew', function(newValue, oldValue) {
      if ($scope.isnew == true) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.isnew = 1;
        $location.search('new', 1);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      } else if ($scope.isnew == false) {
        $scope.params.isnew = 0;
        $location.search('new', null);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });

    $scope.$watch('promotion', function(newValue, oldValue) {
      if ($scope.promotion == true) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.promotion = 1;
        $location.search('promotion', 1);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      } else if ($scope.promotion == false) {
        $scope.params.promotion = 0;
        $location.search('promotion', null);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });




    $scope.$watch('bundle', function(newValue, oldValue) {
      if ($scope.bundle == true) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $scope.params.bundle = 1;
        $location.search('bundle', 1);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      } else if ($scope.bundle == false) {
        $scope.params.bundle = 0;
        $location.search('bundle', null);
        $scope.params.page = 1; $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.is_city = response.data.response.is_city;
          $scope.real_count = response.data.response.real_count;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
        });
      }
    });
    $scope.clearFilters = function() {

      $scope.params.new = null;
      $scope.params.promotion = null;
      $scope.params.bundle = null;
      $location.search('q', "");
      $location.search('query', "");
      $location.search('age', []);
      $location.search('cat', []);
      $location.search('new', null);
      $location.search('promotion', null);
      $location.search('bundle', null);
      $location.search('price_from', "");
      $location.search('price_to', "");
      $location.search('date_from', "");
      $location.search('date_to', "");

      $route.reload();

    };

    $scope.subCategory = function(id) {
        if(typeof $scope.categories[id].subs != 'undefined' &&  $scope.categories[id].subs == false ) {
          $scope.categories[id].subs = true;
        }else{
          $scope.categories[id].subs = false;
        }
    }

    $scope.changePage = function(page) {
      if(typeof page != 'undefined') {
        
      if (page > $scope.pagination.page) {
        if ($scope.pagination.nextPage == true) {
          $scope.params.page = page;
        }
      }
      if (page < $scope.pagination.page) {
        if ($scope.pagination.prevPage == true) {
          $scope.params.page = page;
        }
      }
      if ($scope.params.page != $scope.pagination.page) {
        $rootScope.loaders.homeCtrl.searchResults = 1;
        $location.search('page', $scope.params.page);
        searchService.getAttractions($scope.params).then(function(response) {
          $scope.attractions = response.data.response.results;
          $scope.pagination = response.data.response.pagination;
          $scope.real_count = response.data.response.real_count;
          $scope.is_city = response.data.response.is_city;
          $scope.check_search_text();
          $rootScope.loaders.homeCtrl.searchResults = 0;
          $scope.loading = false;
          $('html, body').animate({
            scrollTop: $("#box_top").offset().top
        }, 1000);
        });
      }
    }
    }

    $scope.sort = function(sort) {
      if(sort != $scope.params.sort) {
      if($scope.params.sort == 'newest'){
        $scope.params.sort = 'reviews';
      }else{
        $scope.params.sort = 'newest';
      }
      }

    }

    $scope.onecat = function(id){
      $rootScope.search.catSelection = [];
      $rootScope.search.catSelection.push(id);
      $location.search('cat', $rootScope.search.catSelection);
    }
    /*
    $scope.toggleCategorySelection = function toggleCategorySelection(cat) {
      var idx = $scope.categorySelection.indexOf(cat);
      // is currently selected
      if (idx > -1) {
        $scope.categorySelection.splice(idx, 1);
        $location.search('cat', $scope.categorySelection);
        $location.search('page', $scope.params.page);
      }
      // is newly selected
      else {
        $scope.categorySelection.push(cat);
        $location.search('cat', $scope.categorySelection);
        $location.search('page', $scope.params.page);
      }
    };
    */


    $scope.ageToggle = function ageToggle(age) {

      var idx = $scope.ageSelection.indexOf(age);
      var idx2 = $rootScope.search.ageSelection.indexOf(age);
      console.log(idx2);
      // is currently selected
      if (idx2 > -1) {
        $rootScope.search.ageSelection.splice(idx2, 1);
        $location.search('age', $rootScope.search.ageSelection);
      }
      // is newly selected
      else {
        $rootScope.search.ageSelection.push(age);
        $location.search('age', $rootScope.search.ageSelection);
      }
    };

    $scope.openReservationModal = function(attr_id, phone) {
      ngDialog.open({
        template: 'contactModal',
        className: 'ngdialog-theme-default login-modal',
        data: {
          phone: phone
        }
      });

    };


    $scope.clearSearch  = function() {
      $rootScope.search.c = "";
      angular.forEach( $scope.citiesBrowser , function( value, key ) {
          value.ticked = false;
      });
    }
    $scope.clearQ  = function() {
      $rootScope.search.q = "";
    }

    $scope.clearPriceFrom  = function() {
      $rootScope.search.price_from = "";
    }

    $scope.clearPriceTo  = function() {
      $rootScope.search.price_to = "";
    }

    $scope.clearDateFrom  = function() {
      $rootScope.search.date_from = "";

    }

    $scope.clearDateTo  = function() {
      $rootScope.search.date_to = "";
    }

    $scope.clearQuery  = function() {
      $rootScope.search.query = "";
    }

    $scope.clearBundle  = function() {
      $scope.bundle = 0;
    }

    $scope.clearIsNew  = function() {
      $scope.isnew = 0;
    }
    $scope.clearPromotion  = function() {
      $scope.promotion = 0;
    }


    $scope.toggleAgeSelectionRootHome = function toggleAgeSelectionRootHome(age) {

          var idx = $rootScope.search.ageSelection.indexOf(age);
          // is currently selected
          if (idx > -1) {
            $rootScope.search.ageSelection.splice(idx, 1);
            $rootScope.search.ageSelection.sort(function(a, b) {
              return a - b;
            });



          }
          // is newly selected
          else {
            $rootScope.search.ageSelection.push(age);
            $rootScope.search.ageSelection.sort(function(a, b) {
              return a - b;
            });



          }

        };

      $scope.second = function second(age) {

              var idx = $rootScope.search.ageSelectionSecond.indexOf(age);
              // is currently selected
              if (idx > -1) {
                $rootScope.search.ageSelectionSecond.splice(idx, 1);
                $rootScope.search.ageSelectionSecond.sort(function(a, b) {
                  return a - b;
                });

              }
              // is newly selected
              else {
                $rootScope.search.ageSelectionSecond.push(age);
                $rootScope.search.ageSelectionSecond.sort(function(a, b) {
                  return a - b;
                });

              }
      };
    $scope.toggleAgeSelectionRoot = function toggleAgeSelectionRoot(age) {

      var idx = $rootScope.search.ageSelection.indexOf(age);
      // is currently selected
      if (idx > -1) {
        $rootScope.search.ageSelection.splice(idx, 1);
        $rootScope.search.ageSelection.sort(function(a, b) {
          return a - b;
        });

          $location.search('age', $rootScope.search.ageSelection);

      }
      // is newly selected
      else {
        $rootScope.search.ageSelection.push(age);
        $rootScope.search.ageSelection.sort(function(a, b) {
          return a - b;
        });

          $location.search('age', $rootScope.search.ageSelection);

      }

    };



    $scope.toggleCategorySelectionRoot = function toggleCategorySelectionRoot(cat, parent, name, child) {
      child = typeof child != 'undefined'? child : false;
      $rootScope.mlodzi.catclick = true;

        $timeout(function(){
          var idx = $rootScope.search.catSelection.indexOf(cat);

          // is currently selected
          if (idx > -1) {
            $rootScope.search.catSelection.splice(idx, 1);

              $location.search('cat', $rootScope.search.catSelection);



          }


          // is newly selected
          else {
            if(child == 'child'){
              var idp = $rootScope.search.catSelection.indexOf(parent);
              if (idp > -1) {
                $rootScope.search.catSelection.splice(idp, 1);

              }
            }
            $rootScope.search.catSelection.push(cat);



              $location.search('cat', $rootScope.search.catSelection);

          }
        },500);


        $timeout(function(){

          $rootScope.mlodzi.catclick = false;
        },1000);
    };



    $scope.categorySelect = function categorySelect(cat) {
        var idx = $rootScope.search.catSelection.indexOf(cat);
        $rootScope.search.catSelection = [];
        $rootScope.search.catSelection.push(cat);
        $location.search('age', $rootScope.search.ageSelection);
        $location.search('cat', $rootScope.search.catSelection);
    };

  }]);
