CoRobic.controller('AppController', ['$scope', '$rootScope', '$timeout', 'appService', 'ngDialog', 'Facebook', '$http', function($scope, $rootScope, $timeout, appService, ngDialog, Facebook,$http) {
    $rootScope.files_url = files_url;
    $rootScope.authUser = JSON.parse(auth);
    $rootScope.authentication = {};
    $rootScope.authentication.userProfile = $rootScope.authUser;
    $rootScope.fb_slider = false;

    $rootScope.search = {
      q: "",
      q_second: "",
      ageSelection: [],
      ageSelectionSecond: [],
      catSelect: [],
    };

    $scope.moreLessText = 'Pokaż więcej';
    $scope.moreLess = function(){
      if($scope.more){
        $scope.more = false;
        $scope.moreLessText = 'Pokaż więcej';
      }else{
        $scope.more = true;
        $scope.moreLessText = 'Pokaż mniej';
      }
    }

    //HOME
      if(city.length > 0){
        $rootScope.city = city;
      }else{
        $rootScope.city = 'Trwa wczytywanie...';
      }

      $scope.verifiedAccessible = false;
      $scope.moreshadow = false;
      $scope.moreshadowheight = 0;
      $rootScope.catsheight = 148;
      $scope.moreText = 'Pokaż więcej';
      $scope.moreOn = function() {
        if($scope.moreshadow) {
          $scope.moreshadow = false;
          $scope.moreshadowheight = 0;
          $rootScope.catsheight = 148;
          $scope.moreText = 'Pokaż więcej';
          $('.more').find('img').attr('src', 'img/cats/more.png');
        }else{
          $scope.moreshadow = true;
          $scope.moreshadowheight = $('body').outerHeight();
          $rootScope.catsheight = 0;
          $scope.moreText = 'Pokaż mniej';
          $('.more').find('img').attr('src', 'img/cats/more_off.png');
        }
      }


      //Pobieranie lokalizacji
      if(city.length == 0) {
        navigator.geolocation.getCurrentPosition(getLocation, errorHandler, {});
      }

      function getLocation(position) {

        $scope.geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        $scope.geocoder.geocode({'latLng': latlng}, function(results, status) {

          if(results[0].address_components[3].long_name) {

            $timeout(function() {
              $rootScope.city = results[0].address_components[3].long_name;
              $scope.$apply();
              //Zapisanie miasta do sesji php
              appService.setSession('city', $rootScope.city).then(function(data) {

              });

              if(controller == 'Home' && action == 'index'){
                appService.verifiedOnly($rootScope.city).then(function(data) {
                  $scope.verifiedAccessible = data.data;
                });
              }
            });
          }

        });
      }

      function errorHandler(positionError) {
        console.log(positionError);
        $timeout(function() {
          $rootScope.city = 'Cała polska';
          $scope.$apply();
        });
      }


      $rootScope.getRandomInt = function(minimum, maximum) {
          return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
      }


      $scope.background = $rootScope.getRandomInt(1,10);


   $scope.openRemind = function() {
    ngDialog.open({
      template: 'remindDialog',
      scope: $scope,
      className: 'ngdialog-theme-default login-modal'
    });
   }

   $rootScope.remind = function() {
     console.log($scope);
      appService.remind($scope.remind_email).then(function(response) {

          if(response.status == 200) {
            ngDialog.close({
              id: 'remindDialog'
            });
            window.location.reload();
          } else {

          }
        });
    }

    $scope.login = function(type) {
      $rootScope.authentication.type = type;
      ngDialog.open({
        template: 'login-modal',
        scope: $scope,
        className: 'ngdialog-theme-default login-modal'
      });
    }

    $scope.openHowItWorks = function() {
      ngDialog.open({
        template: 'loginHowItWorks',
        className: 'ngdialog-theme-default login-modal'
      });
    };

    $scope.openForm = function(id) {
      $rootScope.attraction_id = id;
      ngDialog.open({
        template: 'formDialog',
        controller: 'AppController',
        className: 'ngdialog-theme-default login-modal',
      });
    };

    $scope.form = function(){
      if(typeof $scope.form_content != 'undefined' && $scope.form_content.length > 0) {
      var data = {
        attraction_id: $rootScope.attraction_id,
        email: $scope.form_email,
        content: $scope.form_content
      };
      $http({
        method: 'POST',
        url: base_path+'/forms/add',
        data: data
      }).then(function successCallback(response) {
          ngDialog.closeAll();
          window.location.reload();
        }, function errorCallback(response) {
        });
      }
    }


    $scope.location = function() {
      ngDialog.open({
        template: 'lang-modal',
        scope: $scope,
        controller: ['$scope', function($scope) {


          $(document).keydown(function(e) {
              
              var code = e.keyCode || e.which,
                input = $('[name="lang_input"]'),
                autocomplete = $('.lang_picker');
              console.log('test1', autocomplete.length);
              if (!autocomplete.length)
                  return;
              
              var autocomplete_option = $('li:visible', autocomplete);
              var autocomplete_option_all = $('li', autocomplete);
              console.log('test2', autocomplete_option);
              if (!autocomplete_option.length)
                  return;

              var autocomplete_option_active_index = autocomplete_option.hasClass('active')
                  ? autocomplete_option.filter('.active').index()
                  : -1;

              switch(e.which) {
                  case 37: // left
                  break;

                  case 38: // up
                    var active = false;
                    
                    if(autocomplete.find('li.active').length > 0) {
                      active = autocomplete.find('li.active')[0];
                    }


                    if(active == false) {
                      active = autocomplete_option.first();
                    }else{
                      $(active).removeClass('active');

                        if($(active).prev(':visible').length == 0) {
                          if(typeof $(active).prevUntil(':visible').last().prev()[0] != 'undefined') {
                            active = $($(active).prevUntil(':visible').last().prev()[0]);
                          }else{
                            active = autocomplete_option.last();
                          }
                          
                        }else{
                          active = $($(active).prev(':visible')[0]);
                        }
                    }


                    active.addClass('active');

                    // Autocomplete scroll
                    scrollAutocomplete(active, autocomplete);
    
                  break;

                  case 39: // right
                  break;

                  case 40: // down
                    var active = false;
                    
                    if(autocomplete.find('li.active').length > 0) {
                      active = autocomplete.find('li.active')[0];
                    }


                    if(active == false) {
                      active = autocomplete_option.first();
                    }else{
                      $(active).removeClass('active');

                        if($(active).next(':visible').length == 0) {

                          if(typeof $(active).nextUntil(':visible').last().next()[0] != 'undefined'){
                            active = $($(active).nextUntil(':visible').last().next()[0]);
                          }else{
                            active = autocomplete_option.first();
                          }
                          
                        }else{
                          active = $($(active).next(':visible')[0]);
                        }
                    }


                    active.addClass('active');

                    // Autocomplete scroll
                    scrollAutocomplete(active, autocomplete);

                  break;
                  

                  case 13:
                    if(autocomplete.find('li.active').length > 0) {
                      active = autocomplete.find('li.active')[0];
                      $(active).find('a').click();
                    }
                  break;
                  default: return; // exit this handler for other keys
              }

               function resetAutocompleteOptionActive() {
                    e.preventDefault();
                    autocomplete_option
                        .removeClass('active');
                }

                function scrollAutocomplete(autocomplete_option_active, autocomplete) {
                    if (autocomplete_option_active.position().top + autocomplete.scrollTop() < autocomplete.scrollTop())
                        autocomplete.mCustomScrollbar('scrollTo',
                            autocomplete_option_active.position().top + autocomplete.scrollTop()
                        );
                    else if (autocomplete_option_active.position().top + autocomplete_option_active.outerHeight(true) + autocomplete.scrollTop() > autocomplete.scrollTop() + autocomplete.outerHeight())
                      autocomplete.mCustomScrollbar('scrollTo',
                            autocomplete_option_active.position().top + autocomplete_option_active.outerHeight(true) + autocomplete.scrollTop() - autocomplete.outerHeight()
                        );
                }
              // e.preventDefault(); // prevent the default action (scroll / move caret)
          });


          $scope.$watch('lang_input', function(newValue, oldValue) {
            if(typeof newValue != 'undefined') {

              if(newValue.length > 0 && newValue != oldValue) {
                $.each($('.lang_picker').find('li a'), function(i, val) {
                  $(val).parent().css('display', 'none');
                  $(val).css('display', 'none');
                });
                $('.lang_picker').addClass('active');
                $.each($('.lang_picker').find('li a'), function(i, val) {
                  var el = $(val).text().toLowerCase();
                  if(el.indexOf(newValue.toLowerCase()) != -1) {
                    $(val).parent().css('display', 'block');
                    $(val).css('display', 'block');
                  }
                  // console.log(anchor.text());
                });
              }else{
                $('.lang_picker').removeClass('active');
              }
            }
          });
        }],
        className: 'ngdialog-theme-default lang-modal'
      });
    }

    $scope.selectLocation = function(city) {
      appService.setSession('city', city).then(function(data) {
        window.location.reload();
      });
    }


    $scope.fblogin = function() {
      Facebook.login(function(response) {
        appService.fblogin({
          access_token: response.authResponse.accessToken
        }).then(function(log) {

          window.location.reload();

        });

      }, {
        scope: 'email'
      });
    }
    //END OF HOME



    $scope.openReview = function(attraction_id, bizid, slug) {

      if (auth) {
        $rootScope.review_data = {
          review_id: null,
          count: null,
          real_count: 5,
          content: '',
          bizid: null
        };

        $rootScope.review_data.attraction_id = attraction_id;
        $rootScope.review_data.bizid = bizid;
        $rootScope.review_data.slug = slug;

        ngDialog.open({
          template: 'reviewDialog',
          controller: 'ReviewsController',
          className: 'ngdialog-theme-default login-modal'
        });

      } else {
        ngDialog.open({
          template: 'loginDialog',
          controller: 'AppController',
          className: 'ngdialog-theme-default login-modal'
        });
      }

    };


    if(window.location.hash) {
      var hash = window.location.hash.replace('#', '');
      hash = hash.replace('!', '');
      if(controller == 'Static') {
        $("html, body").animate({ scrollTop: $(hash).offset().top }, 1000);
      }

    }

    $('#tab').on('click', function() {
      if($rootScope.fb_slider == false) {
        $('#face-slider').css('right', '0px');
        $rootScope.fb_slider = true;
      }else{
        $('#face-slider').css('right', '-287px');
        $rootScope.fb_slider = false;
      }
    });


    
}]);